import React from "react";
import DashboardNavbar from "../components/navbars/DashboardNavbar";
import ScrollToTop from "../components/scroll/ScrollToTop";

const DashboardLayout = ({ children }) => {
  return (
    <>
      <ScrollToTop />
      <DashboardNavbar />
      {children}
    </>
  );
};

export default DashboardLayout;
