import React from "react";
import Faq from "../../components/home/Faq";
import Footer from "../../components/home/Footer";
import Section1 from "../../components/home/Section1";
import Section3 from "../../components/home/Section3";
import Section5 from "../../components/home/Section5";
import Section6 from "../../components/home/Section6";
import Section7 from "../../components/home/Section7";
import Sector from "../../components/home/Sector";
import YouTubeVideo from "../../components/home/YouTubeVideo";

const Home = () => {
    const youtubeVideoId = "uFsSsgUDbd8";
  return (
    <div className="w-full flex flex-col bg-white overflow-hidden">
      <Section1 />
      <div className="w-full pt-20 px-10 flex flex-col gap-10 justify-center items-center relative isolate overflow-hidden lg:overflow-visible">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <h2
          data-aos="fade-up"
          className="font-semibold text-5xl max-lg:text-3xl text-primary leading-normal"
        >
          About Wealth Frontier
        </h2>
        <p className="text-sm leading-5 text-gray-500 text-justify">
          Take a moment to watch the video below and discover how Wealth
          Frontier can empower your financial journey. In just a few minutes,
          you'll learn about our mission, innovative tools, and the benefits we
          offer to help you build and manage your wealth effectively.
        </p>
        <div className="w-full flex items-center justify-center py-10">
          <YouTubeVideo videoId={youtubeVideoId} />
        </div>
      </div>
      <Section3 />
      <Sector />
      <Section5 />
      <Section6 />
      <Faq />
      <Section7 />
      <Footer />
    </div>
  );
};

export default Home;
