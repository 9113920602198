import React from "react";
import HomeNavbar from "../components/navbars/HomeNavbar";
import ScrollToTop from "../components/scroll/ScrollToTop";

const GuestLayout = ({ children }) => {
  return (
    <>
      <ScrollToTop/>
      <HomeNavbar />
      {children}
    </>
  );
};

export default GuestLayout;
